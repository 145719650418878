/*!
 * Minimal Mistakes Jekyll Theme 4.17.2 by Michael Rose
 * Copyright 2013-2019 Michael Rose - mademistakes.com | @mmistakes
 * Licensed under MIT (https://github.com/mmistakes/minimal-mistakes/blob/master/LICENSE)
*/

@import "font-awesome/font-awesome"; // main partials

/* Variables */
@import "minimal-mistakes/variables";

/* Mixins and functions */
@import "minimal-mistakes/vendor/breakpoint/breakpoint";
@include breakpoint-set("to ems", true);
@import "minimal-mistakes/vendor/magnific-popup/magnific-popup"; // Magnific Popup
@import "minimal-mistakes/vendor/susy/susy";
@import "minimal-mistakes/mixins";

/* Core CSS */
@import "minimal-mistakes/reset";
@import "minimal-mistakes/base";
@import "minimal-mistakes/forms";
@import "minimal-mistakes/tables";
@import "minimal-mistakes/animations";

/* Components */
@import "minimal-mistakes/buttons";
@import "minimal-mistakes/notices";
@import "minimal-mistakes/masthead";
@import "minimal-mistakes/navigation";
@import "minimal-mistakes/footer";
@import "minimal-mistakes/custom-footer";
@import "minimal-mistakes/search";
@import "minimal-mistakes/syntax";

/* Utility classes */
@import "minimal-mistakes/utilities";

/* Layout specific */
@import "minimal-mistakes/page";
@import "minimal-mistakes/archive";
@import "minimal-mistakes/sidebar";
@import "minimal-mistakes/print";

@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);

@font-face {
  font-family: "Tempus Sans ITC";
  src: url("/assets/fonts/TEMPSITC.TTF");
}
